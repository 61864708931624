<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="loadData"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="itemsPerPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>usuarios</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Agregar Usuario</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        name="ListUsers"
        class="position-relative"
        striped
        hover
        responsive
        :per-page="itemsPerPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        show-empty
        empty-text="No se encontrarón resultados"
        @filtered="onFiltered"
      >
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.photo"
                :text="data.item.name"
                :variant="`light-${data.item.status ? 'success' : 'danger'}`"
                :to="{ name: 'inicio', params: { id: data.item.email } }"
              />
            </template>
            <b-link
              :to="{ name: 'inicio', params: { id: data.item.email } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small
              class="text-muted"
            >@{{ (data.item.email).split('@')[0] }}</small>
          </b-media>
        </template>
        <template #cell(rol)="data">
          {{ getRoleLabel(data.item.record.rol) }}
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${data.item.status ? 'success' : 'danger'}`"
            class="text-capitalize"
          >
            <feather-icon
              :icon="data.item.status ? 'CheckIcon' : 'XIcon'"
            />
          </b-badge>
        </template>
        <template #cell(verified)="data">
          <b-badge
            pill
            :variant="`light-${data.item.verified ? 'success' : 'danger'}`"
            class="text-capitalize"
          >
            <feather-icon
              :icon="data.item.verified ? 'CheckIcon' : 'XIcon'"
            />
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'inicio', params: { id: data.item.email } }"
            >
              <feather-icon
                icon="FileTextIcon"
                class="inline"
              />
              <span class="align-middle ml-50">Detalles</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'inicio', params: { id: data.item.email } }"
            >
              <feather-icon
                icon="EditIcon"
                class="inline"
              />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon
                icon="TrashIcon"
                class="inline"
              />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >Mostrando del
              {{ itemsPerPage + 1 * currentPage - itemsPerPage }} al
              {{ itemsPerPage * currentPage }} de
              {{ items.length }} usuarios</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="rowsCount ? rowsCount : items.length"
              :per-page="itemsPerPage"
              align="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BPagination,
  BFormInput,
  BButton,
  BCard,
  BMedia,
  BLink,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import firebase from 'firebase/app'
import laboratorio from '@/../apis/Laboratorio'
import UserListAddNew from './Add.vue'
import 'firebase/auth'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BPagination,
    BFormInput,
    BButton,
    BCard,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    vSelect,
    UserListAddNew,
  },
  data() {
    return {
      pageOptions: [3, 5, 10, 15, 20, 25, 50, 100],
      itemsPerPage: 10,
      currentPage: 1,
      items: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'user', label: 'Usuario', sortable: true },
        { key: 'email', label: 'Correo Electronico', sortable: true },
        { key: 'record.departamento', label: 'Departamento', sortable: true },
        { key: 'record.position', label: 'Puesto', sortable: true },
        { key: 'rol', label: 'Rol', sortable: true },
        { key: 'status', label: 'Activo' },
        { key: 'verified', label: 'Verificado' },
        { key: 'actions', label: 'Acciones' },
      ],
      rowsCount: null,
      isAddNewUserSidebarActive: false,
      roleOptions: [
        { label: 'Master', value: 'master' },
        { label: 'Administrador', value: 'admin' },
        { label: 'Colaborador', value: 'colab' },
        { label: 'Automatizacion', value: 'automatizacion' },
        { label: 'BioEtanol', value: 'bioetanol' },
        { label: 'Laboratorio', value: 'laboratorio' },
        { label: 'Bodegas', value: 'bodegas' },
        { label: 'Laboratorio Admin.', value: 'lab_admin' },
      ],
      statusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.loadData()
    firebase.auth().currentUser.getIdToken(true).then(idToken => {
      console.log(idToken)
    })
  },
  methods: {
    onFiltered(filteredItems) {
      this.rowsCount = filteredItems.length
      this.currentPage = 1
    },
    loadData() {
      laboratorio.actions.obtenerUsuarios().then(value => {
        this.items = value.data.data
      })
    },
    getRoleLabel(value) {
      const role = this.roleOptions.find((role) => role.value === value);
      return role ? role.label : 'Unknown';
    },
  },
}
</script>

<style>
.page-item.prev-item button {
  border-radius: 50%;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
